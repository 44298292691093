<template>

  <div class="add__task">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title">
              {{ block.name }}
            </h1>
            <p class="log_info">
              Created by {{ block.createdBy ? block.createdBy.name : '' }} on {{ dateFormatWithTime(block.createdAt) }}<br>Last updated on {{ dateFormatWithTime(block.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="6"
        cols="md-12"
      >
        <div class="user_block ac_type">
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="text-capitalize">
              {{ block.status }}
            </h5>
          </div>
          <div class="action_button">
            <b-button
              v-b-modal.block-all-modal-update-status
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
                size="16"
              />
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      class="px-3 py-2"
    >
      <b-row>
        <b-col cols="md-9">
          <b-form-group
            label="Task Name*"
            label-for="h-task-block-taskName"
            label-cols-md="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Task Name"
              vid="taskName"
              rules="required"
            >
              <b-form-input
                id="h-task-block-taskName"
                v-model="taskName"
                placeholder="Task Name"
                :state="errors.length > 0 ? false : null"
                name="taskName"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="md-9">
          <b-form-group
            label="Remarks"
            label-for="h-task-block-taskRemarks"
            label-cols-md="2"
          >
            <validation-provider
              #default="{ errors }"
              name="Remarks"
              vid="taskRemarks"
              rules=""
            >
              <b-form-textarea
                id="h-task-block-taskRemarks"
                v-model="taskRemarks"
                placeholder="Remarks"
                :state="errors.length > 0 ? false : null"
                name="taskRemarks"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row
      class="content-header v-sticky-sidebar-container"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2 top-info-right"
        cols="12"
        md="4"
      >
        <vue-sticky-sidebar
          class="sidebar"
          :top-spacing="topSpacing"
          container-selector=".v-sticky-sidebar-container"
          inner-wrapper-selector=".sidebar__inner"
        >
          <b-card
            id=""
            no-body
            class="padding-two p-1"
          >
            <b-card
              id=""
              no-body
              class="p-1"
            >
              <b-row
                v-for="(opt, key) in block.fields"
                :key="key"
              >
                <b-col cols="md-12">

                  <b-form-group
                    v-if="opt.type == 'text'"
                    :label="opt.label"
                    :label-for="'event-custom-field-' + key"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="opt.label"
                      :vid="'event-custom-field-' + key"
                      :rules="opt.required == 'yes' ? 'required' : ''"
                    >
                      <b-form-input
                        :id="'event-custom-field-' + key"
                        v-model="inputValues[key].value"
                        :placeholder="opt.placeholder"
                        :state="(errors.length > 0) ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    v-if="opt.type == 'textarea-normal'"
                    :label="opt.label"
                    :label-for="'event-custom-field-' + key"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="opt.label"
                      :vid="'event-custom-field-' + key"
                      :rules="opt.required == 'yes' ? 'required' : ''"
                    >
                      <b-form-textarea
                        :id="'event-custom-field-' + key"
                        v-model="inputValues[key].value"
                        trim
                        :placeholder="opt.placeholder"
                        :state="(errors.length > 0) ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    v-if="opt.type == 'textarea-rich'"
                    :label="opt.label"
                    :label-for="'event-custom-field-' + key"
                  >
                    <validation-provider
                      :name="opt.label"
                      :vid="'event-custom-field-' + key"
                      :rules="opt.required == 'yes' ? 'required' : ''"
                    >
                      <!-- <b-form-textarea
                        :id="'event-custom-field-' + key"
                        v-model="inputValues[key].value"
                        trim
                        :placeholder="opt.placeholder"
                        :state="(errors.length > 0) ? false : null"
                      /> -->
                      <quill-editor
                        v-model="inputValues[key].value"
                        :options="editorOption"
                      >
                        <div
                          id="toolbar"
                          slot="toolbar"
                        >
                          <!-- Add a bold button -->
                          <button class="ql-bold">
                            Bold
                          </button>
                          <button class="ql-italic">
                            Italic
                          </button>
                          <button class="ql-underline">
                            Underline
                          </button>
                          <select class="ql-size">
                            <option value="small" />
                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                            <option selected />
                            <option value="large" />
                            <option value="huge" />
                          </select>

                          <select class="ql-align">
                            <option selected="selected" />
                            <option value="center" />
                            <option value="right" />
                            <option value="justify" />
                          </select>
                        </div>
                      </quill-editor>
                    </validation-provider>
                  </b-form-group>

                  <validation-provider
                    v-if="opt.type == 'select'"
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        :id="'event-custom-field-' + key"
                        v-model="inputValues[key].value"
                        label="value"
                        :placeholder="opt.placeholder"
                        :options="opt.extraOptions"
                        :reduce="value => value.key"
                        :clearable="false"
                        @input="setRecipients(key)"
                      />
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    v-if="opt.type == 'multi-select'"
                    #default="{ errors }"
                    :name="opt.label"
                    :vid="'event-custom-field-' + key"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        :id="'event-custom-field-' + key"
                        v-model="inputValues[key].value"
                        label="value"
                        multiple
                        :placeholder="opt.placeholder"
                        :options="opt.extraOptions"
                        :reduce="value => value.key"
                        :clearable="false"
                        @input="setRecipients(key)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="opt.type == 'select' && ['pre-defined recipient', 'data-source', 'another block'].includes(inputValues[key].value)"
                  cols="md-12"
                >
                  <div
                    v-for="(field, rindex) in recipientFields"
                    :key="rindex"
                  >
                    <b-form-group
                      v-if="field.type == 'tag'"
                      :label="field.label"
                      :label-for="'h-recipients-fields' + rindex"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Label"
                        vid="label"
                        :rules="field.required == 'yes' ? 'required' : ''"
                      >
                        <b-form-tags
                          v-model="field.value"
                          input-id="tags-separators"
                          separator=";"
                          :placeholder="field.placeholder"
                          no-add-on-enter
                          class="mb-2"
                          :state="(errors.length > 0) ? false : null"
                        />
                      </validation-provider>
                    </b-form-group>

                    <validation-provider
                      v-if="field.type == 'select'"
                      #default="{ errors }"
                      :name="field.label"
                      :vid="'h-recipients-fields-' + rindex"
                      :rules="field.required == 'yes' ? 'required' : ''"
                    >
                      <b-form-group
                        :label="field.label"
                        :label-for="'h-recipients-fields-' + rindex"
                        :state="(errors.length > 0) ? false : null"
                      >
                        <v-select
                          :id="'h-recipients-fields-' + rindex"
                          v-model="field.value"
                          label="value"
                          :placeholder="field.placeholder"
                          :options="field.extraOptions"
                          :reduce="value => value.key"
                          :clearable="false"
                          @input="setFinalRecipients(key, rindex)"
                        />
                      </b-form-group>
                    </validation-provider>
                    <b-form-group
                      v-if="field.type == 'auto-suggest'"
                      :label="field.label"
                      :label-for="'h-recipients-fields' + rindex"
                    >
                      <validation-provider
                        #default="{}"
                        name="Label"
                        vid="label"
                        :rules="field.required == 'yes' ? 'required' : ''"
                      >
                        <vue-autosuggest
                          :suggestions="field.extraOptions"
                          :limit="10"
                          :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: field.placeholder }"
                          @selected="(...args) => setBlock(...args, key, rindex)"
                          @input="(...args) => suggestOnInputChange(...args, key, rindex)"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item }}</span>
                          </template>
                        </vue-autosuggest>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div
                v-if="block.name == 'Form' || block.name == 'Input Fields'"
                class="p-1 purple-row"
              >
                <b-row>
                  <b-col
                    v-if="customFields.length > 0"
                    md="12"
                    class=""
                  >
                    <span class="py-1">{{ customFields.length }} field(s) added</span>
                    <b-button
                      v-b-modal.add-input-fields
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant="flat-primary"
                      class="primary-button-with-background no-background float-right"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class=""
                        size="20"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    v-else
                    md="12"
                  >
                    <b-button
                      v-b-modal.add-input-fields
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="button"
                      variant=""
                      class="primary-button-with-background purple-button"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                        size="16"
                      />
                      <span class="align-middle">Add Field</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-card>
        </vue-sticky-sidebar>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right d-md-block d-none mb-1"
        md="8"
        cols="12"
      >
        <b-card
          no-body
          class="mb-50 padding-two background-card scroll-item-1"
        >
          <b-row
            class="mb-1"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left top-info-right"
              cols="md-12"
              md="6"
            >
              <h4>Preview</h4>
            </b-col>
          </b-row>
          <b-card
            no-body
            class="mb-50 scroll-item-1"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  :icon="block.icon ? block.icon : ''"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                {{ taskName }}
              </h3>
            </template>
            <b-card
              v-if="block.name == 'Send Email With Attachment'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <email-template
                :input-values="inputValues"
                :custom-fields="recipientFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'File Upload'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <file-upload-template
                :input-values="inputValues"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Send SMS Message'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <send-sms-template
                :input-values="inputValues"
                :custom-fields="recipientFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Send Telegram Message'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <send-telegram-message-template
                :input-values="inputValues"
                :custom-fields="recipientFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Form' || block.name == 'Input Fields'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <input-field-template
                :input-values="inputValues"
                :fields="customFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Link'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <link-template
                :input-values="inputValues"
                :fields="customFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Verification'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4>
              <verification-template
                :input-values="inputValues"
                :fields="customFields"
              />
            </b-card>
            <b-card
              v-else-if="block.name == 'Inventory Request'"
              no-body
              class="mb-50 scroll-item-1"
            >
              <!-- <h4 class="ml-auto mt-1">
                #{{ inputValues[0] ? inputValues[0].value : '' }}
              </h4> -->
              <div class="d-flex justify-content-between mt-1">
                <h4 style="font-weight: 500; font-size: 15px; color: #000000;">
                  Status: <span class="text-capitalize">{{ inputValues[4] ? (inputValues[4].value ? inputValues[4].value : '-') : '-' }}</span>
                </h4>
                <h4 class="">
                  #{{ inputValues[0] ? inputValues[0].value : '' }}
                </h4>
              </div>
              <inventory-request-template
                :input-values="inputValues"
              />
            </b-card>
            <b-card
              v-else
              no-body
              class="mb-50 scroll-item-1"
            >
              <p>Nothing to Preview</p>
            </b-card>
          </b-card>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="block-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="allBlockStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="add-input-fields"
      ref="add-input-fields-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Done"
      cancel-title="Cancel"
      centered
      size="lg"
      no-close-on-backdrop
      title="Fields"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="fieldsForm"
        >
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
            class="field__group"
          >
            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Field Type"
                vid="fieldType"
                rules="required"
              >
                <b-form-group
                  label="Field Type*"
                  :label-for="'h-activity-type-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.inputTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-activity-type-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.inputTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.inputTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-12">
              <div
                class="row"
              >
                <b-col cols="md-12">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-12">
                  <b-form-group
                    label="Placeholder"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="4"
                  >
                    <b-form-input
                      :id="'h-activity-type-placeholder' + index"
                      v-model="field.placeholder"
                      placeholder="Field Placeholder"
                      name="placeholder"
                      @input="field.placeholderValidation == true ? field.placeholderValidation = false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'radio-multiple' || field.type == 'radio-single'"
                  cols="md-12"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-activity-type-extraOptions' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="'h-activity-type-label' + index + 'extraOptions' + key"
                            v-model="opt.value"
                            placeholder="Enter an option name"
                            :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                            name="value"
                            @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeCustomFieldOptions(index, key)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'datasource-select'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Datasource"
                    vid="datasource"
                    rules="required"
                  >
                    <b-form-group
                      label="Datasource*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].value"
                        label="title"
                        :options="dataSourceOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].valueValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].valueValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="field.type == 'file'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upload File Type"
                    vid="fileType"
                    rules="required"
                  >
                    <b-form-group
                      label="Upload File Type*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].value"
                        label="title"
                        placeholder="Select from list"
                        multiple
                        :options="fileTypeOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].valueValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].valueValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="field.type == 'block-select'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Reference Block ID"
                    vid="reference"
                    rules="required"
                  >
                    <b-form-group
                      label="Reference Block ID*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].key"
                        label="title"
                        :options="dataSourceOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].keyValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].keyValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="md-12">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-activity-type-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>

            <b-col cols="md-12">
              <div
                class="d-flex flex-row"
                align-v="center"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-danger"
                  class="primary-button-with-background no-background float-right"
                  @click="removeCustomField(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="cursor-pointer red-trash-button"
                  />
                </b-button>

                <div class="action_button ml-auto">
                  <b-button
                    v-show="index != 0"
                    variant="flat-primary"
                    @click="moveField(index, index-1)"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="index != (customFields.length - 1)"
                    variant="flat-primary"
                    @click="moveField(index, index+1)"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                </div>
              </div>

              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Add a New Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add a New Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BFormRadio, BFormRadioGroup,
  BInputGroupAppend, BInputGroup, BFormTags,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import 'array.prototype.move'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'

import EmailTemplate from './EmailTemplate.vue'
import FileUploadTemplate from './FileUploadTemplate.vue'
import SendSmsTemplate from './SendSmsTemplate.vue'
import SendTelegramMessageTemplate from './SendTelegramMessageTemplate.vue'
import InputFieldTemplate from './InputFieldTemplate.vue'
import LinkTemplate from './LinkTemplate.vue'
import VerificationTemplate from './VerificationTemplate.vue'
import InventoryRequestTemplate from './InventoryRequestTemplate.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    BInputGroupAppend,
    BInputGroup,
    BFormTags,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueAutosuggest,
    'vue-sticky-sidebar': vueStickySidebar,

    EmailTemplate,
    FileUploadTemplate,
    SendSmsTemplate,
    SendTelegramMessageTemplate,
    InputFieldTemplate,
    LinkTemplate,
    VerificationTemplate,
    InventoryRequestTemplate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      block: {},
      status: 'active',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      taskName: '',
      taskRemarks: '',
      inputValues: [],
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Compose message',
      },
      customFields: [],
      recipientFields: [],
      inputTypeOptions: [
        { title: 'Text Input', code: 'text' },
        { title: 'Date Picker', code: 'date-picker' },
        { title: 'Time Picker', code: 'time-picker' },
        { title: 'Text Area', code: 'textarea' },
        { title: 'File Upload', code: 'file' },
        { title: 'Single Selection', code: 'radio-single' },
        { title: 'Multiple Selection', code: 'radio-multiple' },
        { title: 'Dropdown with Datasource', code: 'datasource-select' },
        { title: 'Dropdown with Data from Another Block', code: 'block-select' },
      ],
      dataSourceOptions: [
        { title: 'users.name', code: 'users.name' },
        { title: 'users.email', code: 'users.email' },
      ],
      fileTypeOptions: [
        { title: 'JPEG Images', code: 'image/jpeg' },
        { title: 'PNG Image', code: 'image/png' },
        { title: 'CSV File', code: 'text/csv' },
        { title: 'Microsoft Word File', code: 'application/msword' },
        { title: 'Microsoft Word (OpenXML (.docx)) File', code: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
        { title: 'PDF File', code: 'application/pdf' },
        { title: 'Microsoft PowerPoint File', code: 'application/vnd.ms-powerpoint' },
        { title: 'Microsoft PowerPoint (OpenXML (.pptx)) File', code: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
        { title: 'Microsoft Excel File', code: 'application/vnd.ms-excel' },
        { title: 'Microsoft Excel (OpenXML (.xlsx)) File', code: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      ],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      userDataArray: [],

      required,
    }
  },
  created() {
    this.$http.get(`operation/task-block-components/${this.$route.params.id}`)
      .then(response => {
        document.title = `Task - ${response.data.name} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
        this.block = response.data || {}

        this.status = this.block.status

        this.block.fields.forEach(arrayItem => {
          const newObj = {}
          newObj.key = arrayItem.label
          newObj.value = ''
          this.inputValues.push(newObj)
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get('operation/task-block-components/respond-with/user-options')
      .then(response => {
        response.data.users.forEach(arrayItem => {
          const newObj = {}

          newObj.key = arrayItem._id
          newObj.value = arrayItem.name
          this.userDataArray.push(newObj)
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    moveField(from, to) {
      this.customFields.move(from, to)
    },
    submitForm() {
      this.$refs.allBlockStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/task-block-components/${this.$route.params.id}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.block.status = response.data.data.status || 'active'
              this.status = this.block.status
              this.$swal({
                title: 'Status Updated!',
                html: 'The status has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    setRecipients(key) {
      const { value } = this.inputValues[key]
      this.recipientFields = []
      if (['Send Email With Attachment', 'Send SMS Message', 'Send Telegram Message', 'Inventory Request'].includes(this.block.name)) {
        if (value === 'pre-defined recipient') {
          this.recipientFields.push({
            type: 'tag',
            label: 'Pre-defined recipient(s)',
            value: [],
            extraOptions: [],
            required: 'yes',
            placeholder: 'Add recipients here',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })
        } else if (value === 'data-source') {
          if (this.block.name === 'Inventory Request') {
            this.recipientFields.push({
              type: 'select',
              label: 'Possible Assignees',
              value: [],
              extraOptions: this.userDataArray,
              required: 'yes',
              placeholder: 'Possible Assignees',
              typeInvalid: false,
              typeInvalidMessage: '',
              labelInvalid: false,
              labelInvalidMessage: '',
              placeholderInvalid: false,
              placeholderInvalidMessage: '',
              requiredInvalid: false,
              requiredInvalidMessage: '',
            })
          } else {
            this.recipientFields.push({
              type: 'select',
              label: 'Select Data Source',
              value: '',
              extraOptions: [
                {
                  key: 'User',
                  value: 'User',
                },
                {
                  key: 'Service Form',
                  value: 'Service Form',
                },
              ],
              required: 'yes',
              placeholder: 'Select Data Source',
              typeInvalid: false,
              typeInvalidMessage: '',
              labelInvalid: false,
              labelInvalidMessage: '',
              placeholderInvalid: false,
              placeholderInvalidMessage: '',
              requiredInvalid: false,
              requiredInvalidMessage: '',
            })

            this.recipientFields.push({
              type: 'select',
              label: 'Select Recipient',
              value: '',
              extraOptions: [],
              required: 'yes',
              placeholder: 'Select Recipient',
              typeInvalid: false,
              typeInvalidMessage: '',
              labelInvalid: false,
              labelInvalidMessage: '',
              placeholderInvalid: false,
              placeholderInvalidMessage: '',
              requiredInvalid: false,
              requiredInvalidMessage: '',
            })
          }
        } else if (value === 'another block') {
          this.recipientFields.push({
            type: 'auto-suggest',
            label: 'Search Block ID',
            value: '',
            extraOptions: [{
              data: [
                this.inputValues[0].value,
              ],
            }],
            required: 'yes',
            placeholder: 'Key in Block ID or Search here',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })

          this.recipientFields.push({
            type: 'select',
            label: 'Select Recipient',
            value: '',
            extraOptions: [],
            required: 'yes',
            placeholder: 'Select Recipient',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })
        }
      }
    },

    setFinalRecipients(key, rindex) {
      const { value } = this.inputValues[key]
      const sourceType = this.recipientFields[rindex].value
      const block = this.block.name
      if (value === 'data-source') {
        if (sourceType === 'Service Form') {
          this.recipientFields[rindex + 1].value = ''
          this.recipientFields[rindex + 1].extraOptions = []
          if (block === 'Send Email With Attachment') {
            this.recipientFields[rindex + 1].extraOptions = [
              {
                key: 'Customer',
                value: 'Customer',
              },
            ]
          } else if (block === 'Send SMS Message' || block === 'Send Telegram Message') {
            this.recipientFields[rindex + 1].extraOptions = [
              {
                key: 'Customer',
                value: 'Customer',
              },
              {
                key: 'Agent',
                value: 'Agent',
              },
            ]
          }
        } else if (sourceType === 'User') {
          this.recipientFields[rindex + 1].value = ''
          this.recipientFields[rindex + 1].extraOptions = this.userDataArray
          // this.$http.get('operation/task-block-components/respond-with/user-options')
          //   .then(response => {
          //     response.data.users.forEach(arrayItem => {
          //       const newObj = {}
          //
          //       newObj.key = arrayItem._id
          //       newObj.value = arrayItem.name
          //       this.recipientFields[rindex + 1].extraOptions.push(newObj)
          //     })
          //   })
          //   .catch(error => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
          //         icon: 'AlertTriangleIcon',
          //         variant: 'danger',
          //       },
          //     })
          //   })
        }
      }
    },

    suggestOnInputChange(text, key, rindex) {
      if (text === '' || text === undefined) {
        return
      }

      console.log(key, rindex)
      // const filteredData = this.recipientFields[rindex].extraOptions[0].data.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)

      // this.recipientFields[rindex].extraOptions = [{
      //   data: filteredData,
      // }]
    },

    setBlock(suggestionItem, suggestionIndex, key, rindex) {
      this.recipientFields[rindex].value = suggestionItem.item
      this.recipientFields[rindex + 1].extraOptions = []
      this.block.fields.forEach(arrayItem => {
        if (arrayItem.type === 'text') {
          const newObj = {}
          newObj.key = arrayItem.label
          newObj.value = arrayItem.label
          this.recipientFields[rindex + 1].extraOptions.push(newObj)
        }
      })
    },

    addCustomField() {
      this.customFields.push({
        type: 'text',
        label: '',
        extraOptions: [],
        required: 'yes',
        file: null,
        base64File: null,
        placeholder: '',
        typeInvalid: false,
        typeInvalidMessage: '',
        fileInvalid: false,
        fileInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        placeholderInvalid: false,
        placeholderInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].inputTypeInvalid === true ? this.customFields[index].inputTypeInvalid = false : null
      if (['radio-single', 'radio-multiple', 'datasource-select', 'block-select', 'file'].includes(this.customFields[index].type)) {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1)
    },
  },
}
</script>

<style scoped>
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }

  .red-trash-button {
    color: #D91B35 !important;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }

  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }

  .autosuggest__results-container .autosuggest__results {
    background-color: #fff;
    margin-top: 1rem;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
  }
  .autosuggest__results-container .autosuggest__results {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    max-height: 40vh;
  }
</style>
